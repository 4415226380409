.slider {
  // visualització al carregar
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }

  padding-left: $spacing * 2;
  padding-right: $spacing * 2;
  margin-top: $spacing * 2;
  @media #{$medium-up} {
    margin-top: 0;
  }
}
.slick-next,
.slick-prev {
  height: 30px;
  width: 30px;
  &::before {
    content: "";
    font-size: 1rem;
  }
}
.slick-prev {
  left: 0;
  &::before {
    @include sprite("ico-back");
  }
}
.slick-next {
  right: 0;
  &::before {
    @include sprite("ico-next");
  }
}
