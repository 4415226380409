.centres-container {
  color: #fff;
  background-color: $primary-color;
  background-color: rgba($primary-color, 0.8);
  padding: $spacing;
  @media #{$medium-up} {
    padding: $spacing * 2;
    overflow-x: hidden;
    .js & {
      width: 40%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    .js .home &,
    .js .tots-els-centres & {
      left: auto;
      right: 0;
    }
  }
  @media #{$large-up} {
    // afegit per quan hi ha pocs centres al llistat, perquè es vegi millor el mapa. Es pot eliminar si el llistat de centres és més llarg.
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }
  h1 {
    font-family: $title-font-family;
    font-size: rem-calc(50);
  }
  h2 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: rem-calc(20);
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(27);
    }
  }
  ul {
    font-size: rem-calc(16);
  }
  h3 {
    font-size: rem-calc(18);
  }
  a {
    color: #fff;
    // &.icon {
    //   display: inline-block;
    // }
  }
  &--info {
    h2,
    p {
      font-size: 1rem;
    }
    h2 {
      text-transform: none;
      font-weight: bold;
      &::before {
        content: "";
        margin-right: $spacing * 0.25;
        vertical-align: bottom;
      }
      &.horari::before {
        @include sprite("ico-horari");
      }
      &.tel::before {
        @include sprite("ico-phone");
      }
      &.loc::before {
        @include sprite("ico-loc");
      }
      &.email::before {
        @include sprite("ico-mail");
        vertical-align: baseline;
      }
      &.web::before {
        @include sprite("ico-web");
      }
    }
    p {
      font-weight: normal;
    }
  }

  details {
    border-bottom: 1px solid #fff;
    padding-bottom: $spacing*.5;
    summary {
      cursor: pointer;
    }
    h3 {
      font-size: rem-calc(20);
      font-weight: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::after {
        content: '';
        border: solid 1px #fff;
        border-width: 1px 1px 0 0;
        display: block;
        transform: rotate(135deg);
        width: 10px;
        height: 10px;
        margin-right: $spacing*.5;
      }
    }
    ul {
      font-size: 1rem;
      margin-top: $spacing*.5;
    }
    a {
      @include hover;
    }
    &[open] {
      h3::after {
        transform: rotate(-45deg);
      }
    }
  }
}
