// video embed
// .embed-container {
//   position: relative;
//   padding-bottom: 56.25%;
//   height: 0;
//   overflow: hidden;
//   max-width: 100%;
//   &--laterals {
//     overflow: visible;
//     @media #{$medium-up} {
//       &:before,
//       &:after {
//         content: "";
//         display: block;
//         position: absolute;
//         top: 0;
//         bottom: 0;
//         width: 100%;
//         background-image: linear-gradient(334deg, $grad-end, $grad-start);
//       }
//       &:before {
//         left: -100%;
//         background-image: none;
//         border-left: 20px solid #000;
//       }
//       &:after {
//         right: -100%;
//       }
//     }
//   }
//   iframe,
//   object,
//   embed {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//   }
// }
iframe {
  border: 0;
}

.map {
  background-color: #eee;
  .js & {
    height: rem-calc(599);
  }
}
// InfoWindow Google Maps
.gm-style-iw-c {
  padding: $spacing * 0.5 !important;
}
#content {
  h2 {
    font-size: rem-calc(16);
    margin-bottom: $spacing * 0.25;
  }
  p {
    font-size: rem-calc(14);
  }
}
p.esp {
  margin-bottom: rem-calc(15);
  font-size: rem-calc(16);
}
