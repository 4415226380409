.centers-list {
    margin-bottom: 50px;
    //margin-top: 50px;
    li {
       text-align: left;
       div {
        padding: 30px;
        min-height: 351px;
       }
    }
    .cat-prov {
        border-radius: 4px;
        opacity: 1;
        background-image: linear-gradient(270deg, rgba(87, 201, 223, 1) 0%, rgba(0, 167, 94, 1) 100%);
        h3 {
            color: #fff;
            font-size: 42px;
            text-transform: none;
            font-family: $title-font-family-300;
            margin-top: 10px;
        }
    }
    .centre-prov {
        border-radius: 4px;
        border: 1px solid rgba(151,151,151, 1);
        opacity: 1;
        position: relative;
        text-align: left;
        //background-image: url('/media/css/img/mapa-seu-catalunya.svg');
        background-repeat: no-repeat;
        background-position: 90% 10%;
        background-size: auto;
        .logo-seu {
            padding-bottom: 15px;
        }
        h3 {
            font-size: 30px;
            text-transform: none;
            font-family: $title-font-family-700;
            position: relative;
            padding-top: 15px;
            line-height: 32px;
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translateX(-50%);
                width: 25px;
                height: 1px;
                background-color: #615F63;
                top: 0;
                left: 13px;
            }
        }
        img[src$=".svg"] {
            max-height: 50px;
        }
        p {
            font-size: 14px;
            color:#615F63;
            font-family: $title-font-family;
            text-align: left;
            margin-bottom: 2px;
        }
        p.telf {
            a {
              font-size: 16px;
              text-decoration: none;
            }
        }
        p.mail {
            a {
              font-size: 18px;
              text-decoration: none;
            }
        }
        .list-btn {
            position: absolute;
            bottom: 20px;
            li {
                display: inline-flex;
                margin-top: 5px;
            }
        }
    }
}
