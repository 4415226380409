/* Cookie Dialog */
#gdpr-cookie-message {
  position: fixed;
  right: 0;
  bottom: 60px;
  max-width: 450px;
  background-color: #FFF;
  opacity: 0.9;
  padding: 30px 35px;
  box-shadow: 0 6px 6px rgba(0,0,0,0.25);
  margin-left: 30px;
  z-index: 9999;
}
  #gdpr-cookie-message hr{
      margin: 10px 0;
  }
  #gdpr-cookie-message h4 {
      color: #000;
      font-size: 18px;
      font-weight: bolder;
      margin-bottom: 10px;
      text-transform: none;
  }
  #gdpr-cookie-message h5 {
      color: var(--red);
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: none;
  }
#gdpr-cookie-message p, #gdpr-cookie-message ul {
  color: #000;
  font-size: 15px;
  line-height: 1.5em;
  margin: 0 0 1.125rem;
}
#gdpr-cookie-message p:last-child {
  margin-bottom: 0;
  text-align: right;
}
#gdpr-cookie-message li {
  width: 49%;
  display: inline-block;
  margin-bottom: 1em;
  padding-left: 0;
  &::before{
    display: none;
  }
}

#gdpr-cookie-message label {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#gdpr-cookie-message a {
  color: #000000 !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: bolder;
  padding-bottom: 2px;
  border-bottom: 1px dotted rgba(255,255,255,0.75);
  transition: all 0.3s ease-in;
}
  #gdpr-cookie-message a:hover {
      color: white;
      border-bottom-color: var(--red);
      transition: all 0.3s ease-in;
  }
  #gpdr-cookie-message a:focus{
      outline: none !important;
  }
#gdpr-cookie-message button,
button#ihavecookiesBtn {
  font-size: 14px;
  padding: 7px 20px;
  margin-left: 0;
  cursor: pointer;
  transition: all 0.3s ease-in;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 8px;
}
  #gdpr-cookie-message button#gdpr-cookie-accept, #gdpr-cookie-message button#gdpr-cookie-reject{
      background-color: #000000;
      color: #FFF;
      border: none;

  }
  #gdpr-cookie-message button#gdpr-cookie-accept:hover,#gdpr-cookie-message button#gdpr-cookie-reject:hover,#gdpr-cookie-message button#gdpr-cookie-advanced:hover{
    transition: all 0.3s ease-in;
    opacity: 0.5;
  }
  #gdpr-cookie-message button#gdpr-cookie-advanced{
    background-color: #000000;
    color: #FFF;
    border: none;
}

#gdpr-cookie-message button:disabled {
  /*opacity: 0.3;*/
}
  #gdpr-cookie-message input[type="checkbox"] {
      float: none;
      margin-top: 0;
      margin-right: 5px;
      background: #e0e0e0;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      opacity: 0.9;
      border: none;
      vertical-align: middle;
      margin-bottom: 0;
  }
      #gdpr-cookie-message input[type="checkbox"]:focus{
          outline: none !important;
      }
      #gdpr-cookie-message input[type="checkbox"]:hover{
          background: #000;
          cursor: pointer;
          border: 0;
      }
      #gdpr-cookie-message input[type="checkbox"]:checked{
          background: #000;
      }
      #gdpr-cookie-message input[type="checkbox"]:disabled{
          background: #979197;
      }
          #gdpr-cookie-message input[type="checkbox"]:disabled:hover{
              background: #979197;
              cursor: default !important;
          }
      #gdpr-cookie-message input[type="checkbox"]:checked:after {
          content:'';
          background: url('img/check-ico-cookies.png') no-repeat;
          width: 17px;
          height: 14px;
          position: relative;
          top: 5px;
          left: 4px;
          display: block;
          border: none;
          transform: none
      }

@media (max-width: 490px) {
  #gdpr-cookie-message li{
      width: 100%;
  }
  #gdpr-cookie-message button#gdpr-cookie-advanced{
      margin-top: 1em;
  }
  #gdpr-cookie-message button{
      float: left;
  }
  .isiOS.isiPhone #gdpr-cookie-message {
      padding: 10px 20px;
  }
}


.scrolltop {
    // display: none;
    transition: transform .2s;
    z-index: 2;
    // text-align: right;
    border: 0;
    width: vr(2.5);
    height: vr(2.5);
    display: block;
    margin: 0 auto vr(1);
    @media #{$large-up} {
      display: none;
      width: auto;
      height: auto;
      position: fixed;
      right: $column-gutter*.5;
      bottom: 1.2rem;
    }
    &:hover,
    &:focus {
      border: 0 !important;
    }
    img {
      margin-bottom: 0;
      max-width: 63px;
    }
  }

  .btn-cookies{
    color: rgb(255, 255, 255) !important;
    right: 5.5rem;
    background:none;
    text-decoration: none;
    span{
        display: block;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 30px;
        padding: 18px;
        &:hover{
            background-color: rgba(0, 0, 0, 1) !important;
        }
    }

    @media (max-width: 490px) {
        width: 50%;
        height: auto;
    }



  }
