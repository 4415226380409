.home-slider {
    display: none;
    @media #{$medium-up} {
        display: block;
    }
    .slick-slide > div > div {
        position: relative;
    }
    &.slick-initialized .row {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }
    img {
        object-fit: cover;
        display: inherit;
        width: 100%;
    }
    &__box {
        .js & {
            display: none;
        }
        display: block;
        color: #fff;
        background-color: rgba(#615F63,.8);
        padding: $spacing $spacing*2;
        h4 {
            font-family: $title-font-family;
            font-size: rem-calc(40);
            line-height: 1;
            @media #{$large-up} {
                font-size: rem-calc(55);
                span {
                    display: block;
                }
            }
        }
    }
    &.slick-initialized .home-slider__box {
        display: block;
    }
    .slick-next, .slick-prev {
        z-index: 1;
        filter: brightness(0) invert(100%);
    }
    .slick-dots {
        bottom: -45px;
    }
}