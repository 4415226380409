// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-download-name: 'icon-download';
$icon-download-x: 83px;
$icon-download-y: 0px;
$icon-download-offset-x: -83px;
$icon-download-offset-y: 0px;
$icon-download-width: 30px;
$icon-download-height: 20px;
$icon-download-total-width: 113px;
$icon-download-total-height: 72px;
$icon-download-image: 'img/spritesheet.png?1582752792396';
$icon-download: (83px, 0px, -83px, 0px, 30px, 20px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'icon-download', );
$icon-enllacos-pdf-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-x: 83px;
$icon-enllacos-pdf-y: 20px;
$icon-enllacos-pdf-offset-x: -83px;
$icon-enllacos-pdf-offset-y: -20px;
$icon-enllacos-pdf-width: 22px;
$icon-enllacos-pdf-height: 23px;
$icon-enllacos-pdf-total-width: 113px;
$icon-enllacos-pdf-total-height: 72px;
$icon-enllacos-pdf-image: 'img/spritesheet.png?1582752792396';
$icon-enllacos-pdf: (83px, 20px, -83px, -20px, 22px, 23px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'icon-enllacos-pdf', );
$icon-enllacos-name: 'icon-enllacos';
$icon-enllacos-x: 64px;
$icon-enllacos-y: 40px;
$icon-enllacos-offset-x: -64px;
$icon-enllacos-offset-y: -40px;
$icon-enllacos-width: 11px;
$icon-enllacos-height: 18px;
$icon-enllacos-total-width: 113px;
$icon-enllacos-total-height: 72px;
$icon-enllacos-image: 'img/spritesheet.png?1582752792396';
$icon-enllacos: (64px, 40px, -64px, -40px, 11px, 18px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'icon-enllacos', );
$icon-link-name: 'icon-link';
$icon-link-x: 83px;
$icon-link-y: 43px;
$icon-link-offset-x: -83px;
$icon-link-offset-y: -43px;
$icon-link-width: 22px;
$icon-link-height: 23px;
$icon-link-total-width: 113px;
$icon-link-total-height: 72px;
$icon-link-image: 'img/spritesheet.png?1582752792396';
$icon-link: (83px, 43px, -83px, -43px, 22px, 23px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'icon-link', );
$icon-menu-small-name: 'icon-menu-small';
$icon-menu-small-x: 0px;
$icon-menu-small-y: 0px;
$icon-menu-small-offset-x: 0px;
$icon-menu-small-offset-y: 0px;
$icon-menu-small-width: 40px;
$icon-menu-small-height: 40px;
$icon-menu-small-total-width: 113px;
$icon-menu-small-total-height: 72px;
$icon-menu-small-image: 'img/spritesheet.png?1582752792396';
$icon-menu-small: (0px, 0px, 0px, 0px, 40px, 40px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'icon-menu-small', );
$logo-linkedin-name: 'logo-linkedin';
$logo-linkedin-x: 0px;
$logo-linkedin-y: 40px;
$logo-linkedin-offset-x: 0px;
$logo-linkedin-offset-y: -40px;
$logo-linkedin-width: 32px;
$logo-linkedin-height: 32px;
$logo-linkedin-total-width: 113px;
$logo-linkedin-total-height: 72px;
$logo-linkedin-image: 'img/spritesheet.png?1582752792396';
$logo-linkedin: (0px, 40px, 0px, -40px, 32px, 32px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'logo-linkedin', );
$logo-twitter-name: 'logo-twitter';
$logo-twitter-x: 32px;
$logo-twitter-y: 40px;
$logo-twitter-offset-x: -32px;
$logo-twitter-offset-y: -40px;
$logo-twitter-width: 32px;
$logo-twitter-height: 32px;
$logo-twitter-total-width: 113px;
$logo-twitter-total-height: 72px;
$logo-twitter-image: 'img/spritesheet.png?1582752792396';
$logo-twitter: (32px, 40px, -32px, -40px, 32px, 32px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'logo-twitter', );
$top-name: 'top';
$top-x: 40px;
$top-y: 0px;
$top-offset-x: -40px;
$top-offset-y: 0px;
$top-width: 43px;
$top-height: 24px;
$top-total-width: 113px;
$top-total-height: 72px;
$top-image: 'img/spritesheet.png?1582752792396';
$top: (40px, 0px, -40px, 0px, 43px, 24px, 113px, 72px, 'img/spritesheet.png?1582752792396', 'top', );
$icon-download-2x-name: 'icon-download@2x';
$icon-download-2x-x: 166px;
$icon-download-2x-y: 0px;
$icon-download-2x-offset-x: -166px;
$icon-download-2x-offset-y: 0px;
$icon-download-2x-width: 60px;
$icon-download-2x-height: 40px;
$icon-download-2x-total-width: 226px;
$icon-download-2x-total-height: 144px;
$icon-download-2x-image: 'img/spritesheet@2x.png?1582752792396';
$icon-download-2x: (166px, 0px, -166px, 0px, 60px, 40px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'icon-download@2x', );
$icon-enllacos-pdf-2x-name: 'icon-enllacos-pdf@2x';
$icon-enllacos-pdf-2x-x: 166px;
$icon-enllacos-pdf-2x-y: 40px;
$icon-enllacos-pdf-2x-offset-x: -166px;
$icon-enllacos-pdf-2x-offset-y: -40px;
$icon-enllacos-pdf-2x-width: 44px;
$icon-enllacos-pdf-2x-height: 46px;
$icon-enllacos-pdf-2x-total-width: 226px;
$icon-enllacos-pdf-2x-total-height: 144px;
$icon-enllacos-pdf-2x-image: 'img/spritesheet@2x.png?1582752792396';
$icon-enllacos-pdf-2x: (166px, 40px, -166px, -40px, 44px, 46px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'icon-enllacos-pdf@2x', );
$icon-enllacos-2x-name: 'icon-enllacos@2x';
$icon-enllacos-2x-x: 128px;
$icon-enllacos-2x-y: 80px;
$icon-enllacos-2x-offset-x: -128px;
$icon-enllacos-2x-offset-y: -80px;
$icon-enllacos-2x-width: 22px;
$icon-enllacos-2x-height: 36px;
$icon-enllacos-2x-total-width: 226px;
$icon-enllacos-2x-total-height: 144px;
$icon-enllacos-2x-image: 'img/spritesheet@2x.png?1582752792396';
$icon-enllacos-2x: (128px, 80px, -128px, -80px, 22px, 36px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'icon-enllacos@2x', );
$icon-link-2x-name: 'icon-link@2x';
$icon-link-2x-x: 166px;
$icon-link-2x-y: 86px;
$icon-link-2x-offset-x: -166px;
$icon-link-2x-offset-y: -86px;
$icon-link-2x-width: 44px;
$icon-link-2x-height: 46px;
$icon-link-2x-total-width: 226px;
$icon-link-2x-total-height: 144px;
$icon-link-2x-image: 'img/spritesheet@2x.png?1582752792396';
$icon-link-2x: (166px, 86px, -166px, -86px, 44px, 46px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'icon-link@2x', );
$icon-menu-small-2x-name: 'icon-menu-small@2x';
$icon-menu-small-2x-x: 0px;
$icon-menu-small-2x-y: 0px;
$icon-menu-small-2x-offset-x: 0px;
$icon-menu-small-2x-offset-y: 0px;
$icon-menu-small-2x-width: 80px;
$icon-menu-small-2x-height: 80px;
$icon-menu-small-2x-total-width: 226px;
$icon-menu-small-2x-total-height: 144px;
$icon-menu-small-2x-image: 'img/spritesheet@2x.png?1582752792396';
$icon-menu-small-2x: (0px, 0px, 0px, 0px, 80px, 80px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'icon-menu-small@2x', );
$logo-linkedin-2x-name: 'logo-linkedin@2x';
$logo-linkedin-2x-x: 0px;
$logo-linkedin-2x-y: 80px;
$logo-linkedin-2x-offset-x: 0px;
$logo-linkedin-2x-offset-y: -80px;
$logo-linkedin-2x-width: 64px;
$logo-linkedin-2x-height: 64px;
$logo-linkedin-2x-total-width: 226px;
$logo-linkedin-2x-total-height: 144px;
$logo-linkedin-2x-image: 'img/spritesheet@2x.png?1582752792396';
$logo-linkedin-2x: (0px, 80px, 0px, -80px, 64px, 64px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'logo-linkedin@2x', );
$logo-twitter-2x-name: 'logo-twitter@2x';
$logo-twitter-2x-x: 64px;
$logo-twitter-2x-y: 80px;
$logo-twitter-2x-offset-x: -64px;
$logo-twitter-2x-offset-y: -80px;
$logo-twitter-2x-width: 64px;
$logo-twitter-2x-height: 64px;
$logo-twitter-2x-total-width: 226px;
$logo-twitter-2x-total-height: 144px;
$logo-twitter-2x-image: 'img/spritesheet@2x.png?1582752792396';
$logo-twitter-2x: (64px, 80px, -64px, -80px, 64px, 64px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'logo-twitter@2x', );
$top-2x-name: 'top@2x';
$top-2x-x: 80px;
$top-2x-y: 0px;
$top-2x-offset-x: -80px;
$top-2x-offset-y: 0px;
$top-2x-width: 86px;
$top-2x-height: 48px;
$top-2x-total-width: 226px;
$top-2x-total-height: 144px;
$top-2x-image: 'img/spritesheet@2x.png?1582752792396';
$top-2x: (80px, 0px, -80px, 0px, 86px, 48px, 226px, 144px, 'img/spritesheet@2x.png?1582752792396', 'top@2x', );
$spritesheet-width: 113px;
$spritesheet-height: 72px;
$spritesheet-image: 'img/spritesheet.png?1582752792396';
$spritesheet-sprites: ($icon-download, $icon-enllacos-pdf, $icon-enllacos, $icon-link, $icon-menu-small, $logo-linkedin, $logo-twitter, $top, );
$spritesheet: (113px, 72px, 'img/spritesheet.png?1582752792396', $spritesheet-sprites, );
$retina-spritesheet-width: 226px;
$retina-spritesheet-height: 144px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1582752792396';
$retina-spritesheet-sprites: ($icon-download-2x, $icon-enllacos-pdf-2x, $icon-enllacos-2x, $icon-link-2x, $icon-menu-small-2x, $logo-linkedin-2x, $logo-twitter-2x, $top-2x, );
$retina-spritesheet: (226px, 144px, 'img/spritesheet@2x.png?1582752792396', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$icon-download-group-name: 'icon-download';
$icon-download-group: ('icon-download', $icon-download, $icon-download-2x, );
$icon-enllacos-pdf-group-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-group: ('icon-enllacos-pdf', $icon-enllacos-pdf, $icon-enllacos-pdf-2x, );
$icon-enllacos-group-name: 'icon-enllacos';
$icon-enllacos-group: ('icon-enllacos', $icon-enllacos, $icon-enllacos-2x, );
$icon-link-group-name: 'icon-link';
$icon-link-group: ('icon-link', $icon-link, $icon-link-2x, );
$icon-menu-small-group-name: 'icon-menu-small';
$icon-menu-small-group: ('icon-menu-small', $icon-menu-small, $icon-menu-small-2x, );
$logo-linkedin-group-name: 'logo-linkedin';
$logo-linkedin-group: ('logo-linkedin', $logo-linkedin, $logo-linkedin-2x, );
$logo-twitter-group-name: 'logo-twitter';
$logo-twitter-group: ('logo-twitter', $logo-twitter, $logo-twitter-2x, );
$top-group-name: 'top';
$top-group: ('top', $top, $top-2x, );
$retina-groups: ($icon-download-group, $icon-enllacos-pdf-group, $icon-enllacos-group, $icon-link-group, $icon-menu-small-group, $logo-linkedin-group, $logo-twitter-group, $top-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
