nav {
  @media #{$large-up} {
    margin-top: 0 !important;
  }
}
.main-nav {
  @include undolist;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0;
  color: #000;
  .home & {
    color: #fff;
  }
  @media #{$large-up} {
   position: relative;
   top:1rem;
   text-align:right;
    .home & {
      position: absolute;
      right: $column-gutter * 0.5;
      top: $spacing * 4;
    }
  }
  li {
    font-size: rem-calc(14);  
    display: inline;
    @media #{$medium-up} {
      font-size: 0.95rem;
    }
    &::after {
      content: "-";
      display: inline;
      margin: 0 $spacing * 0.25;
      @media #{$large-up} {
        margin: 0 $spacing * 0.35;
      }
    }
    &:last-child::after {
      display: none;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    white-space: nowrap;
    .home & {
      color: #fff;
    }
    &:hover {
      border-bottom: 1px solid;
    }
    &[aria-current="page"] {
      border-bottom: 1px solid;
    }
  }
}
@media #{$large-up} {
  #menu {
    display: block;
  }
}
