.main-header {
  position: relative;
  text-align: center;
  padding: $spacing 0;
  background-color: #000;
  @media #{$large-up} {
    text-align: left;
    padding: $spacing * 1.3 0;
  }
}
.site-logo {
  line-height: 1;
  font-size: rem-calc(16);
  width: rem-calc(140);
  margin-left: auto;
  margin-right: auto;
  @media #{$large-up} {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
.lang {
  @include undolist;
  text-transform: uppercase;
  font-size: 0;
  font-weight: bold;
  color: #fff;
  position: absolute;
  left: $column-gutter * 0.5;
  top: 0;
  @media #{$medium-up} {
    position: relative;
    display: inline-block;
    top: -3px;
  }
  li {
    font-size: rem-calc(14);
    @media #{$medium-up} {
      font-size: 1rem;
    }
    @media #{$large-up} {
      font-size: rem-calc(16);
    }
    @media #{$medium-up} {
      display: inline;
      &::after {
        content: "|";
        display: inline;
        margin: 0 $spacing * 0.25;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
  a {
    @include hover;
    color: inherit;
    font-weight: 500;
    font-family: $title-font-family-300;
  }
  abbr {
    border-bottom: none;
    text-decoration: none;
  }
}
.site-tools {
  @media #{$medium-up} {
    position: absolute;
    top: 0;
    right: $column-gutter * 0.5;
  }
  a.btn {
    .home & {
      filter: brightness(0) invert(100%);
      &:hover {
        color: $primary-color;
        filter: invert(0);
      }
    }
  }
}
.icon-social {
  display: block;
  position: absolute;
  right: $column-gutter * 0.5;
  top: 0;
  @media #{$medium-up} {
    position: static;
    display: inline-block;
    margin-left: $spacing * 2;
  }
}
.tagline {
  display: block;
  color: #fff;
  font-family: $title-font-family;
  font-weight: bold;
  font-size: rem-calc(40);
  line-height: 1;
  margin-top: $spacing * 2;
  @media #{$medium-up} {
    font-size: rem-calc(60);
    margin-top: $spacing * 7;
    text-align: right;
  }
  @media #{$large-up} {
    font-size: rem-calc(75);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(90);
  }
}
