.sub-home-header {
    height: auto;
    background: no-repeat;
    background-size: cover;
    background-position: center center;
    h1 {
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-family: $title-font-family;
        margin-top: 50px;
    }
    p {
        text-align: center;
        color: #fff;
        font-size: 21px;
        font-family:$title-font-family-light;
        font-weight: 100;
        margin-bottom: 20px;
    }
    @media #{$medium-up} {
        height: 400px;
        h1 {
            font-size: 60px;
            margin-top: 100px;
        }

      }
    @media #{$large-up} {
        height: 541px;
        h1 {
            font-size: 90px;
            margin-top: 150px;
        }
    }
}
