/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: "Museo Sans Rounded 500";
    font-style: normal;
    font-weight: normal;
    src: local("Museo Sans Rounded 500"), url("../../fonts/MuseoSansRounded500.woff") format("woff");
}

@font-face {
    font-family: "Museo Sans Rounded 100";
    src: url("../../fonts/museosansrounded100.woff2") format("woff2"),
        url("../../fonts/museosansrounded100.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Museo Sans Rounded 300';
    src: url('../../fonts/museosansrounded300-webfont.woff2') format('woff2'),
         url('../../fonts/museosansrounded300-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Museo Sans Rounded 700';
    src: url('../../fonts/museosansrounded700-webfont.woff2') format('woff2'),
         url('../../fonts/museosansrounded700-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Museo Sans Rounded 900';
    src: url('../../fonts/museosansrounded900-webfont.woff2') format('woff2'),
         url('../../fonts/museosansrounded900-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aktivgrotesk-light';
    src: url('../../fonts/aktivgrotesk-light-webfont.woff2') format('woff2'),
         url('../../fonts/aktivgrotesk-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
