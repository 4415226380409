.main-footer {
  padding: $spacing * 2 0;
  color: #fff;
  background-color: #000;
  a {
    color: #fff;
    @include hover;
  }
  ul {
    font-size: 0;
    font-weight: 300;
    text-transform: uppercase;
    li {
      display: inline;
      font-size: rem-calc(14);
      &::after {
        content: "-";
        margin-left: $spacing * 0.5;
        margin-right: $spacing * 0.5;
      }
      &:last-child::after {
        display: none;
      }
    }
    em {
      font-style: normal;
    }
  }
  p {
    font-size: rem-calc(14);
  }
  .linkedin-foot {
    float:right;
    span {
        position: relative;
        top: -5px;
        right: 3px;
    }
  }
  .foot-second-row {
    margin-top: 20px;
    .copyright {
        width: fit-content;
        display: inline-flex;
    }
  }
  .nav-foot {
    @media #{$medium-up} {
        display: inline-flex;
        float: right;
    }

    li {
        a {
            text-transform: none;
        }
    }
  }
}
address,
.main-footer p:first-child {
  font-style: normal;
  font-size: 1rem;
  &::before {
    content: "";
    @include sprite("logo-peu");
    display: block;
    margin: 0 auto $spacing*.5;
  }
}
