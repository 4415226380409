.section {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  @media #{$large-up} {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }
  &--intro {
    text-align: center;
    padding-bottom: $spacing;
    @media #{$medium-up} {
      text-align: left;
    }
    h2.logo {
      text-transform: uppercase;
      font-size: 30px;
      font-family: $title-font-family-light;
      span {
        display: block;
      }
      strong {
        font-family: $title-font-family;
      }
      @media #{$medium-up} {
        padding-left: $spacing * 2;
        position: relative;
      }
      @media #{$large-up} {
        //padding-left: $spacing * 9;
      }
      &::before {
        content: "";
        display: block;
        background-image: url('/media/css/img/logo-ev-fons.svg');
        margin: 0 auto $spacing;
        font-size: rem-calc(5);
        width: 109px;
        height: 151px;
        @media #{$medium-up} {
          font-size: rem-calc(10);
          position: absolute;
          left: 0;
          right:0px;
          top:-25px;
        }
        @media #{$large-up} {
          font-size: rem-calc(16);
          top:-30px;
          right: 260px;
        }
      }
    }
    p {
        font-family: $aktiv-grotesk-light;
        font-size: 18px;
        text-align: center;
        margin-top: $spacing;
        @media #{$medium-up} {
            margin-top: 0;
            margin-bottom: $spacing;
          }
    }
    h3 {
      font-size: rem-calc(20);
      font-weight: 300;
      text-transform: uppercase;
      line-height: 1.2;
      @media #{$medium-up} {
        font-size: rem-calc(24);
      }
      @media #{$medium-up} {
        font-size: rem-calc(30);
      }
    }
    .logo-list {
        margin: 0 auto;
        width: fit-content;
        padding-top: 15px;
        li {
            display: inline-flex;
            padding: 0px 20px;
        }
    }
  }
  &--centres {
    padding: 0;
    gap: 3rem;
    // .row {
    //   @media #{$medium-up} {
    //     display: flex;
    //     overflow-y: hidden;
    //   }
    //   @media #{$large-up} {
    //     max-width: none;
    //   }
    > div {
      background-size: cover;
      background-color: #ccc;

      &:nth-child(even) {
        .container {
          background-color: #615f63;
          background-color: rgba(#615f63, 0.8);
        }
      }

    }

    &.dos {
        > div {
            &:nth-child(1) {
                //background-image: url("img/molins-home.jpg");
                /*.container {
                background-color: #615f63;
                background-color: rgba(#615f63, 0.8);
                }*/
            }
            &:nth-child(2) {
                //background-image: url("img/evmedical-santfeliu-home.png");
            }
        }
    }


    &.tres {
      > div {
          &:nth-child(1) {
              //background-image: url("img/molins-home.jpg");
              /*.container {
              background-color: #615f63;
              background-color: rgba(#615f63, 0.8);
              }*/
          }
          &:nth-child(2) {
              //background-image: url("img/evmedical-santfeliu-home.png");
          }
          &:nth-child(3) {
            //background-image: url("img/evmedical-maestranza-home.png");
        }

      }
  }

    // }
    .container {
      color: #fff;
      background-color: $primary-color;
      background-color: rgba($primary-color, 0.8);
      padding: $spacing;
      margin-top: $spacing * 8;
      margin-top: 25vmin;
      position: relative;
      // @media #{$medium-up} {
      //   height: 100%;
      // }
      // @media #{$large-up} {
      //   padding-left: $spacing * 4;
      //   margin-top: $spacing * 10;
      // }
      @media #{$xlarge-up} {
        padding-top: $spacing * 1.5;
        padding-bottom: $spacing * 1.5;
        // margin-top: $spacing * 14;
        // margin-top: 15vmin;
      }
      h2 {
        font-size: rem-calc(30);
        font-family: $title-font-family;
        font-weight: 500;
        line-height: 1;
        // @media #{$medium-up} {
        //   font-size: rem-calc(40);
        // }
        @media #{$large-up} {
          font-size: rem-calc(40);
        }
        @media #{$xlarge-up} {
          font-size: rem-calc(50);
        }
        span {
          @media #{$large-up} {
            display: block;
          }
        }
      }
    }
  }
  &--canal {
    color: #fff;
    background-color: #615f63;
    @media #{$medium-up} {
      > .row > .column > *:first-child {
        display: flex;
        align-items: flex-end;
      }
    }
    h2 {
      font-family: $title-font-family;
      font-size: rem-calc(35);
      font-weight: normal;
      @media #{$medium-up} {
        font-size: rem-calc(40);
      }
      @media #{$large-up} {
        font-size: rem-calc(50);
        padding-left: $spacing * 5;
      }
      @media #{$xlarge-up} {
        font-size: rem-calc(54);
      }
      &::before {
        content: "";
        @include sprite("ico-canal-paciente");
        display: block;
        font-size: 1rem;
        margin-bottom: $spacing;
        @media #{$large-up} {
          position: absolute;
          left: $column-gutter * 0.5;
          top: 0;
        }
      }
      + p {
        font-size: rem-calc(20);
        @media #{$medium-up} {
          font-size: rem-calc(22);
        }
        @media #{$large-up} {
          font-size: rem-calc(25);
          padding-left: $spacing * 5;
        }
      }
    }
    ul {
      font-weight: normal;
      font-size: rem-calc(18);
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
      @media #{$large-up} {
        padding-left: $spacing * 5;
      }
    }
    li {
      padding-left: $spacing * 1.5;
      position: relative;
      &::before {
        content: "";
        @include sprite("bullet-white");
        font-size: 1rem;
        display: block;
        position: absolute;
        left: 0;
        top: 0.55em;
      }
    }
    small {
      font-style: italic;
      font-size: 1rem;
    }
  }
  &--map {
    padding: 0;
    @media #{$medium-up} {
      position: relative;
    }
  }
  &--icons {
    text-align: center;
    ul {
      font-size: 0;
      > * {
        margin: 0;
        + * {
          margin-top: $spacing * 1.5;
        }
      }
    }
    li {
      display: inline-block;
      vertical-align: top;
      font-size: 1rem;
      @media #{$medium-up} {
        .roquetas & {
          width: 33.333%;
        }
        .aguadulce & {
          width: 25%;
        }
        .vera & {
          width: 25%;
        }
        .molins & {
          width: 33.333%;
        }
        .santfeliu & {
          width: 25%;
        }
        margin-top: 0;
        padding-left: $spacing * 0.5;
        padding-right: $spacing * 0.5;
      }
      &::before {
        content: "";
        display: block !important;
        margin: 0 auto $spacing;
        font-size: 1rem;
      }
      &.medicina::before {
        @include sprite("ico-medicina-general");
      }
      /*&.cirugia::before {
        @include sprite("ico-cirugia");
        font-size: 0.9rem;
      }*/
      &.cirugia::before {
        @include sprite("ico-pediatria");
      }
      &.equipo::before {
        @include sprite("ico-equipo");
      }
      &.pediatria::before {
        @include sprite("ico-pediatria");
      }
      &.enfermeria::before {
        @include sprite("ico-enfermeria");
      }
      &.analisis::before {
        @include sprite("ico-analisis");
      }
      &.ginecologia::before {
        @include sprite("ico-ginecologia");
        margin-bottom: rem-calc(10);
      }
      &.xlab::before {
        @include sprite("pict-peu");
      }
      &.gine::before {
        @include sprite("pict-fetus");
      }
      &.psico::before {
        @include sprite("pict-cervell");
      }
      span {
        .aguadulce & {
          font-size: rem-calc(18);
          @media #{$large-up} {
            font-size: rem-calc(20);
          }
          @media #{$xlarge-up} {
            font-size: rem-calc(21);
          }
        }
      }
      .ico {
        display: flex;
        min-height: 100px;
        max-height: 100px;
        margin-bottom: 10px;
        img {
            margin: 0 auto;
        }
      }
    }
    h3 {
      font-size: rem-calc(20);
      font-weight: 300;
      text-transform: uppercase;
    }
    p {
      .roquetas &,
      .molins & {
        font-size: rem-calc(18);
        font-weight: 300;
        margin-top: $spacing * 0.5;
      }
    }
    .aguadulce & {
      // p {
      //   font-size: rem-calc(18);
      //   @media #{$large-up} {
      //     font-size: rem-calc(20);
      //   }
      //   @media #{$xlarge-up} {
      //     font-size: rem-calc(21);
      //   }
      // }
      p:first-of-type {
        @media #{$large-up} {
          width: 40%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      p:last-child {
        @media #{$large-up} {
          width: 65%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      ul {
        font-weight: bold;
        + p {
          @media #{$large-up} {
            width: 65%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  &--centre {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    @media #{$medium-up} {
      overflow-x: hidden;
    }
    .roquetas & {
      background-image: url("img/evmedical-2-copy.jpg");
    }
    .aguadulce & {
      background-image: url("img/evmedical-3.jpg");
    }
    .vera & {
      background-image: url("img/evmedical-4.jpg");
    }
    .molins & {
      background-image: url("img/evmedical-5.jpg");
    }
    .santfeliu & {
        background-image: url("img/sant-feliu-fitxa.png");
      }
    .container {
      color: #fff;
      background-color: $primary-color;
      background-color: rgba($primary-color, 0.8);
      padding: $spacing;
      margin-top: $spacing * 10;
      @media #{$medium-up} {
        text-align: right;
        padding: $spacing * 5 0 $spacing * 2 $spacing * 2;
        margin-top: 0;
        position: relative;
      }
      @media #{$large-up} {
        padding-top: $spacing * 8;
      }
      @media #{$xlarge-up} {
        padding: $spacing * 10 0 $spacing * 3 $spacing * 4;
      }
      &:after {
        @media #{$medium-up} {
          content: "";
          display: block;
          background-color: inherit;
          position: absolute;
          right: -100%;
          top: 0;
          bottom: 0;
          width: 100%;
        }
      }
    }
    h1 {
      font-size: rem-calc(30);
      font-family: $title-font-family;
      font-weight: 500;
      line-height: 1;
      @media #{$medium-up} {
        font-size: rem-calc(40);
      }
      @media #{$large-up} {
        font-size: rem-calc(50);
      }
      @media #{$xlarge-up} {
        font-size: rem-calc(60);
      }
    }
    p {
      font-size: rem-calc(18);
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
      @media #{$large-up} {
        font-size: rem-calc(21);
      }
    }
  }
  &--galeria {
    background-color: #e9e9ea;
    .row:last-child {
      @media #{$medium-up} {
        display: flex;
        align-items: center;
      }
    }
    h2 {
      text-transform: uppercase;
      text-align: center;
      font-weight: 300;
      font-size: rem-calc(22);
      @media #{$medium-up} {
        font-size: rem-calc(26);
      }
      @media #{$large-up} {
        font-size: rem-calc(30);
      }
    }
    ul {
      font-size: rem-calc(18);
      @include stack-small;
      @media screen and (min-width: 50em) {
        column-count: 2;
        column-gap: $spacing;
      }
      li {
        padding-left: $spacing;
        position: relative;
        &:before {
          content: "";
          @include sprite("bullet");
          display: block;
          position: absolute;
          left: 0;
          top: 0.45em;
        }
      }
    }
  }
  &--especialidades {
    // background-size: cover;
    // background-repeat: no-repeat;
    // color: #fff;
    // background-color: #615f63;
    background-color: #e9e9ea;
    // background-blend-mode: multiply;
    // .roquetas & {
    //   background-image: url("img/quirofano.jpg");
    // }
    h2 {
      font-weight: bold;
      font-size: rem-calc(20);
      text-transform: uppercase;
      @media #{$medium-up} {
        font-size: rem-calc(22);
      }
      @media #{$large-up} {
        font-size: rem-calc(24);
      }
    }
    p {
      font-size: rem-calc(18);
      font-weight: normal;
    }
    ul {
      font-size: rem-calc(18);
      // font-weight: normal;
      // @include stack-small;
      > * + * {
        margin-top: $spacing * 0.5;
        @media #{$medium-up} {
          margin-top: 0;
        }
      }
      @media #{$medium-up} {
        // column-count: 2;
        // column-gap: $spacing;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      // @media #{$large-up} {
      //   column-count: 3;
      // }
      li {
        padding-left: $spacing;
        position: relative;
        // page-break-inside: auto;
        @media #{$medium-up} {
          flex-basis: calc(50% - 0.75rem);
          margin: $spacing * 0.25;
        }
        @media #{$large-up} {
          flex-basis: calc(33.333% - 0.75rem);
        }
        &:before {
          content: "";
          @include sprite("bullet");
          display: block;
          position: absolute;
          font-size: 1rem;
          left: 0;
          top: 0.55em;
        }
      }
    }
  }
}

.vera {
  .container {

    @media #{$medium-up} {
      text-align: left;
      padding: $spacing * 5 $spacing * 2 $spacing * 2 0;
    }
    @media #{$large-up} {
      padding-top: $spacing * 8;
    }
    @media #{$xlarge-up} {
      padding: $spacing * 10 $spacing * 3 $spacing * 4 0;
    }
    &:after {
      @media #{$medium-up} {
        left: -100%;

      }
    }
  }
}
