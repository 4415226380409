.btn {
  display: inline-block;
  font-weight: bold;
  font-size: rem-calc(14);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  text-align: center;
  font-family: $title-font-family-700;
  padding: $spacing * 0.3 $spacing;
  text-decoration: none;
  transition: 0.15s;
  transition-property: color, background-color, border-color;
  &:hover,
  &:focus {
    background-color: #fff;
    color: $link-color;
  }
  .container & {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &--invert {
    background-color: $primary-color;
  }
  &--type2 {
    background-color: transparent;
    border-color: #979797;
    color: #615F63;
  }
}
.backbutton {
    margin-bottom: 20px;
    float: right;
}
