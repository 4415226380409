@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local('Lato Light Italic'), local('Lato-LightItalic'), url(../../fonts/Lato-Light-Italic.woff) format('woff'), url(../../fonts/Lato-Light-Italic.ttf) format('truetype'), url(../../fonts/Lato-Light-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'), local('Lato-Italic'), url(../../fonts/Lato-Italic.woff) format('woff'), url(../../fonts/Lato-Italic.ttf) format('truetype'), url(../../fonts/Lato-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(../../fonts/Lato-Light.woff) format('woff'), url(../../fonts/Lato-Light.ttf) format('truetype'), url(../../fonts/Lato-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Lato-Regular.eot);
  src: local('Lato Regular'), local('Lato-Regular'), local('Lato'), url(../../fonts/Lato-Regular.woff) format('woff'), url(../../fonts/Lato-Regular.ttf) format('truetype'), url(../../fonts/Lato-Regular.svg#Lato) format('svg'), url(../../fonts/Lato-Regular.eot?#iefix) format('embedded-opentype'), url(../../fonts/Lato-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(../../fonts/Lato-Bold.woff) format('woff'), url(../../fonts/Lato-Bold.ttf) format('truetype'), url(../../fonts/Lato-Bold.woff2) format('woff2');
}

