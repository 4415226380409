// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-weight: 300;
}
h1,
.t1 {
  font-size: rem-calc(30);
  font-weight: bold;
  line-height: 1.2;
  @media #{$medium-up} {
    font-size: rem-calc(32);
  }
  @media #{$large-up} {
    font-size: rem-calc(34);
  }
}
h2,
.t2 {
  font-size: rem-calc(22);
  font-weight: bold;
  line-height: 1.2;
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  @media #{$medium-up} {
    font-size: rem-calc(26);
  }
}
h3,
.t3 {
  font-size: rem-calc(16);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}
p,
ol,
ul,
dl {
  font-size: rem-calc(18);
  // line-height: 1.6;
  // @media #{$medium-up} {
  //   font-size: rem-calc(18);
  // }
  @media #{$large-up} {
    font-size: rem-calc(20);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(21);
  }

  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

ol,
dl,
dt,
dd,
figure,
img,
form,
hr,
table {
  margin: 0;
  padding: 0;
  // font-size: rem-calc(15);
}
// .main-content {
//   ul,
//   ol,
//   figure {
//     @include stack-small;
//   }
// }
ul {
  list-style: none;
}
ol {
  padding-left: $spacing * 2;
}

figcaption {
  font-size: rem-calc(12);
}

::selection {
  // color: #fff;
  background-color: #d0d0d0;
  -webkit-text-fill-color: $body-font-color;
}
strong,
b {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}

.upp {
  text-transform: uppercase;
}

// links underline
a {
  color: $link-color;
}

// focus
a,
button,
input,
select,
textarea,
[role="button"] {
  &:focus {
    outline: 3px solid $focus-color;
  }
}
